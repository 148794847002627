import jwt_decode from "jwt-decode";
import { Alert } from "../components/Alert";
import { store } from '../stores/configStore';
import { updateUserInfo } from "../stores/appparams/appparams.action";
import { ShowLoading, HideLoading } from "../components/Loading";
const ZaloPay = window.ZaloPay;
const ZPI = window.ZPI_SPA_SDK;

export default class ZaloPaySDK {
  static getEnv() {
    if (window.location.href.includes("dev") || process.env.NODE_ENV === 'development') {
      return "dev";
    }
    return "prod";
  }

  static setTitle = (title) => {
    document.title = title;
    ZPI && ZPI.setTitle(title);
  }

  static isZaloPay = function () {
    return (store.getState().appParams.environment === 'ZaloPay') || (navigator.userAgent.indexOf("ZaloPayClient") > -1);
  }

  static isIOS12 = () => {
    return (navigator.userAgent.indexOf("iPhone OS 12") > -1);
  }

  static isMobileDevice = () => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent));
  };

  static isZPI = function () {
    return store.getState().appParams.environment === 'zpi';
  }

  static isWeb = function () {
    return store.getState().appParams.environment === 'web';
  }

  static isAndroid = function () {
    const isZPIAndroid = !!navigator.userAgent.match(/android/i);
    const isZaloPayAndroid = ZaloPay && (/android/i).test(ZaloPay.ua);
    return isZPIAndroid || isZaloPayAndroid;
  }

  static isIOS = function () {
    const isZPIIOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
    const isZaloPayIOS = ZaloPay && (/iphone|ipad|ipod/i).test(ZaloPay.ua);
    return isZPIIOS || isZaloPayIOS;
  }

  static writeLog = (...args) => {
    var time = (+new Date());
    var formated_time = new Date().toLocaleString();
    var arg = [].slice.call(args);
    var type = arg[0].toLowerCase().trim();
    switch (type) {
      case "error":
        arg.splice(0, 1);
        arg.length === 1 ? console.error(formated_time, arg[0]) : console.error(formated_time, arg);
        break;
      case "warn":
        arg.splice(0, 1);
        arg.length === 1 ? console.warn(formated_time, arg[0]) : console.warn(formated_time, arg);
        break;
      case "info":
        arg.splice(0, 1);
        arg.length === 1 ? console.log(formated_time, arg[0]) : console.log(formated_time, arg);
        break;
      default:
        type = "info";
        arg.length === 1 ? console.log(formated_time, arg[0]) : console.log(formated_time, arg);
        break;
    }
    if (ZaloPay && ZaloPay.isDebug && ZaloPay.call && window.ZaloPayJSBridge && window.ZaloPayJSBridge.call) {
      var opt = {
        type: type,
        time: time,
        data: JSON.stringify(arg)
      };
      ZaloPay.call("writeLog", opt);
    }
  }

  static setUserInfo = (userInfo) => {
    this.writeLog("info", "APP - getAppUserInfo: ", userInfo);
    let muid = "";
    let maccesstoken = "";
    let userid = "";
    let displayname = '';
    let avatarurl = '';

    if (this.isZaloPay()) {
      if (userInfo.muid) {
        muid = userInfo.muid;
      }
      if (userInfo.maccesstoken) {
        maccesstoken = userInfo.maccesstoken;
      }
      if (userInfo.userid) {
        userid = userInfo.userid;
      }
      if (userInfo.displayname) {
        displayname = userInfo.displayname
      }
      if (userInfo.avatarurl) {
        avatarurl = userInfo.avatarurl
      }
    } else {
      if (userInfo.MUID) {
        muid = userInfo.MUID;
      }
      if (userInfo.MAccessToken) {
        maccesstoken = userInfo.MAccessToken;
      }
      if (userInfo.ZaloPayID) {
        userid = userInfo.ZaloPayID;
      }
      if (userInfo.displayname) {
        displayname = userInfo.displayname
      }
      if (userInfo.avatarurl) {
        avatarurl = userInfo.avatarurl
      }
    }

    return { muid, maccesstoken, userid, displayname, avatarurl }
  }

  static inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static getAppUserInfo = (appid, cb) => {
    var self = this;
    if (this.isZaloPay()) {
      ZaloPaySDK.writeLog('ZaloPay: ', ZaloPay);
      ZaloPay.ready(() => {
        ZaloPay.getUserInfo(appid, function (userinfo) {
          let userInfo = self.setUserInfo(userinfo.data);
          store.dispatch(updateUserInfo({ ...userInfo, environment: 'ZaloPay' }));
          cb(userInfo)
        });
      });
    } else {
      try {
        window.addEventListener('load', () => {
          console.log('ZPI: ', ZPI);
          if (ZPI && !this.inIframe()) {
            window.setUserInfo = (userinfo) => {
              let userInfo = this.setUserInfo(userinfo);
              userInfo && store.dispatch(updateUserInfo({ ...userInfo, environment: 'web' }))
            };
            cb({}); return;
          }

          ZPI && ZPI.getProfile(appid)
            .then((data) => {
              console.log('getProfile', data);
              if (data && data.data && data.data.token) {
                const token = data.data.token;
                const decoded = jwt_decode(token);
                console.log('token-decoded: ', decoded);
                const displayName = data.data.userInfo ? data.data.userInfo.displayName : '';
                const avatarUrl = data.data.userInfo ? data.data.userInfo.avatar : '';
                let userInfo = this.setUserInfo({ ...decoded, displayname: displayName, avatarurl: avatarUrl });
                store.dispatch(updateUserInfo({ ...userInfo, environment: 'zpi' }));
                cb(userInfo);
              } else {
                console.log('getUserInfo data wrong', data);
                cb({});
              }
            })
            .catch((error) => {
              console.log('getUserInfo Error: ', error);
              cb({});
            });
        });
      } catch (e) {
        cb({});
      }
    }
  }

  static showLoading = () => {
    ShowLoading();
  };

  static hideLoading = () => {
    HideLoading();
  };

  static showDialog = ({ title = '', message = '', buttons = [{ text: 'OK' }], image }) => {
    Alert({ title, message: message, buttons: buttons, image });
  };

  static launchDeeplink = (url) => {
    ZaloPay && ZaloPay.launchDeeplink(url, function (cb) {
      console.log('launchDeeplink cb', cb);
    });
  }

  static tracking = (eventId, metaData) => {
    try {
      if (this.isZaloPay()) {
        ZaloPay.call("submitEventTracking", {
          eventId: eventId,
          metaData: JSON.stringify(metaData),
        });
      } else {
        let data = { eventID: eventId, metaData: metaData };
        ZPI && ZPI.tracking(data);
      }
    } catch (error) {
      console.log("tracking err", error);
    }
  };

  static payOrder = (data, cb) => {
    const { environment } = store.getState().appParams;
    switch (environment) {
      case "ZaloPay":
        ZaloPay && ZaloPay.payOrder(data, cb);
        break;
      case "web":
        return window.open(data.order_url, '_self');
      case "zpi":
        ZPI && ZPI.showOrderPopup({ zpTransToken: data.zptranstoken, zpAppID: data.appid }, cb);
        break;
      default:
        return window.open(data.order_url, '_self');
    }
  }

  static handleBlurInput = () => {
    if (ZPI) {
      ZPI.handleBlurInput();
    }
  }

  static share = ({ title, url, thumbnail }) => {
    const msg = `Cùng Zalopay chung tay ủng hộ chương trình ${title}. Bấm vào link để tham gia: ${url}`
    console.log('thumbnail: ', thumbnail)
    if (this.isZaloPay()) {
      ZaloPay.share({
        type: 2,
        caption: msg,
        content: url,
        showShareButton: false,
        thumbnail
      })
    } else if (navigator.share) {
      navigator.share({
        text: msg,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    }
  }

  static navigateTo = (url) => {
    ZPI && ZPI.navigateTo(url);
  }

  static closeWindow = () => {
    if (this.isZaloPay()) {
      ZaloPay.closeWindow();
    } else {
      ZPI.closeApp();
    }

  };

}
