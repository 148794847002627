import { all } from 'redux-saga/effects'
import { campaignWatcher } from './campaign/campaign.saga'
import { donationWatcher } from './donation/donation.saga';
import { orderWatcher } from './order/order.saga';
import { listCampaignWatcher } from './listcampaign/listcampaign.saga';
import { resultWatcher } from './result/result.saga';

export default function* rootSaga() {
  yield all([
    campaignWatcher(),
    donationWatcher(),
    orderWatcher(),
    listCampaignWatcher(),
    resultWatcher(),
  ])
}