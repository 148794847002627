import React, {useEffect} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import './react-confirm-alert.css';
import { images } from '../../utils/images';

const ReactConfirmAlert = (props) => {
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            close()
        };
        return () => window.onpopstate = null;
    }, [])

    const handleClickButton = button => {
        window.history.go(-1); //=> in will run onpopstate in useEffect
        let timer = setTimeout(() => {
            if (button.onClick) button.onClick();
            clearTimeout(timer);
        }, 100);
    }

    const close = () => {
        removeElementReconfirm()
    }

    const {title, message, buttons, image = images.zalopay_notify} = props;
    return (
        <div className='react-confirm-alert-overlay'>
            <div style={styles.container}>
                <img style={{width: 120, height: 120, marginTop: 24}} alt='' src={image}/>
                {title ? <div style={styles.title}>{title}</div> : null}
                {message ? <div style={styles.message}>{message}</div> : null}
                <div style={styles.buttonContainer}>
                    {buttons.map((button, index) => {
                        return (
                            <button key={index} onClick={() => handleClickButton(button)}
                                    style={button.styleGray ? styles.subButton : styles.button}>
                                {button.text}
                            </button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        // width: '100vw',
        borderRadius: '4px 4px 0 0',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#ffffff',
        padding: '0 16px 16px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    buttonContainer: {
        width: '100%',
        position: 'relative',
        marginTop: 24,
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        marginTop: 8,
        fontSize: 16,
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        color: '#001f3e'
    },
    message: {
        overflowY: 'scroll',
        maxHeight: '250px',
        padding: '0 40px',
        marginTop: 16,
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "center",
        color: '#001f3e'
    },
    subButton: {
        width: '50%',
        borderRadius: 4,
        backgroundColor: '#d3eeff',
        color: '#0033c9',
        fontSize: 16,
        height: 44,
        fontWeight: "normal",
        textAlign: 'center',
        marginRight: 10,
    },
    button: {
        width: '100%',
        borderRadius: 4,
        backgroundColor: '#0033c9',
        color: '#ffffff',
        fontSize: 16,
        height: 44,
        fontWeight: "bold",
        textAlign: 'center',
    }
}

function createElementReconfirm(properties) {
    let divTarget = document.getElementById('react-confirm-alert')
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<ReactConfirmAlert {...properties} />, divTarget)
    } else {
        // Mount the ReactConfirmAlert component
        document.body.children[0].classList.add('react-confirm-alert-blur')
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm-alert'
        document.body.appendChild(divTarget)
        render(<ReactConfirmAlert {...properties} />, divTarget)
    }
}

function removeElementReconfirm() {
    const target = document.getElementById('react-confirm-alert');
    if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target);
    }

}

export function Alert({title, message, buttons, image}) {
    createElementReconfirm({title, message, buttons, image})
}