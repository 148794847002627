import { types } from './listcampaign.type';

const initState = {
  loading: false,
  fetched: false,
  onGoingCampaigns: [],
  expiredCampaigns: [],
  allCampaigns: [],
  errMsg: '',
}

export const listCampaignReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_LIST_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        errMsg: ''
      }
    case types.GET_LIST_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetched: true,
        errMsg: '',
      };
    case types.GET_LIST_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        fetched: true,
        onGoingCampaigns: [],
        expiredCampaigns: [],
        allCampaigns: [],
        errMsg: action.errMsg
      };
    default:
      return state;
  }
};