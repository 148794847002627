import React from "react";
import { images } from "../utils/images";

export const Checkbox = ({
  className = "",
  isChecking = false,
  text = "",
  onChange = () => undefined,
}) => {
  return (
    <div
      className={"flex-v-center " + className}
      onClick={() => onChange(!isChecking)}
    >
      <img
        alt="check"
        src={
          isChecking
            ? images.icon_checkbox_active
            : images.icon_checkbox_inactive
        }
        style={iconStyle}
      />
      <div className={"font font-size-14 color-black margin-left-8"}>{text}</div>
    </div>
  );
};

const iconStyle = {
  width: 20,
  height: 20,
};
