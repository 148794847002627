import React, { lazy, Suspense } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';

export default function loadable(factory, fallback) {
  const componentPromise = factory;
  const LazyComponent = lazy(componentPromise);

  // eslint-disable-next-line react/display-name
  return (props) => (
    <Suspense fallback={fallback || null}>
      <ErrorBoundary>
        <LazyComponent {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
