import { put, select, takeLeading } from 'redux-saga/effects';
import { getListCampaigns } from "api/donationService";
import { types } from './listcampaign.type';

const BLACK_LIST_ZPI = ["daisunuoclongan2024", "neuco1dieuuoc", "trangmoicuocdoi", "nuocsachtronglanh2024", "hocbongquyvuadinh2024", "buaanchoem2024", "cungemdentruong2024", "phongchongxamhaibaoluctreem2024"];

function* getListCampaignsWatcher() {
  try {
    const { environment } = yield select((state) => state.appParams);
    const [onGoingData, expiredData, allData] = yield Promise.all([
      getListCampaigns({ status: "ACTIVE" }),
      getListCampaigns({ status: "EXPIRED" }),
      getListCampaigns({ status: "UNKNOWN" }),
    ]);
    const isZPI = environment === "zpi";
    const onGoingCampaigns = handleRequestCampaignsData(onGoingData, isZPI);
    const expiredCampaigns = handleRequestCampaignsData(expiredData, isZPI);
    const allCampaigns = handleRequestCampaignsData(allData, isZPI);
    yield put({
      type: types.GET_LIST_CAMPAIGNS_SUCCESS,
      payload: {
        onGoingCampaigns,
        expiredCampaigns,
        allCampaigns,
      } 
    });
  }
  catch (err) {
    console.log(err)
    yield put({ type: types.GET_LIST_CAMPAIGNS_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
  }
}

function handleRequestCampaignsData(resp, isZPI) {
  const { data } = resp || { data: [] };
  if (data && Array.isArray(data)) {
    const filteredCampaigns = data?.filter(campaign => {
      if (!BLACK_LIST_ZPI.includes(campaign.code)) {
        return true;
      }
      if (isZPI) {
        return false;
      }
      return true;
    });
    return [...filteredCampaigns];
  }
  return [];
}

export function* listCampaignWatcher() {
  yield takeLeading(types.GET_LIST_CAMPAIGNS, getListCampaignsWatcher);
}
