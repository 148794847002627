import React, { useState, useEffect } from "react";
import { Button } from "components/Button";
import { DonateInput } from "screens/donate/components/DonateInput";
import { Checkbox } from "components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { getMessages } from "stores/campaign/campaign.action";
import ZaloPaySDK from "utils/zalopay";
import { MAX_AMOUNT_AGREEMENT, MIN_AMOUNT, terms } from "utils/const";
import { tracking } from "utils/tracking";
import { useHistory } from "react-router-dom";

const type_of_donation = "monthly_paymeny_autodebit_entrance";

const Agreement = () => {
  const [amount, setAmount] = useState(10000);
  const [message, setMessage] = useState("");
  const [bindingTerm, setBindingTerm] = useState(terms[1]);
  const [isChecking, setIsChecking] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { onGoingCampaigns } = useSelector((state) => state.listCampaign);
  const { userInfo } = useSelector((state) => state.appParams);
  const listCampaignAgreement = onGoingCampaigns.filter(
    (item) => item.is_agreement_pay
  );

  useEffect(() => {
    if (campaign) {
      dispatch(getMessages({ programId: campaign.id }));
    }
  }, [campaign, dispatch]);

  useEffect(() => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.loaded, {
      type_of_donation,
    });
    ZaloPaySDK.setTitle("Quyên góp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAmountChange = (value) => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.amount, {
      type_of_donation,
    });
    setAmount(value);
  };

  const onMessageChange = (value) => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.message, {
      type_of_donation,
    });
    setMessage(value);
  };

  const onAmountPress = (value) => {
    setAmount(value);
  };

  const onCheckChange = (value) => {
    if (value) {
      ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.clickHideInfo, {
        type_of_donation,
      });
    } else {
      ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.unClickHideInfo, {
        type_of_donation,
      });
    }
    setIsChecking(value);
  };

  const createAgreement = () => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.next, {
      type_of_donation,
    });
    const selectingUnit = campaign.receive_donation_units[0];
    let params = {
      amount,
      due_date: bindingTerm.value,
      program_id: campaign.id,
      organize_id: selectingUnit.id,
      muid: userInfo.mUid,
    };
    setTimeout(() => {
      history.push("/about", { agreementParams: params });
    }, 200);
  };

  const clickCampaign = (item) => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.clickCampaign);
    setCampaign(item);
  };

  const renderBottom = () => {
    return (
      <div className="position-fixed-bottom w-100">
        <div className="padding-16">
          <Button
            disabled={amount < MIN_AMOUNT || amount > MAX_AMOUNT_AGREEMENT || !campaign}
            onPress={createAgreement}
            btnStyle={styles.borderRadius}
            textClassName="font-weight-bold"
            text="Tiếp tục"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="donate-screen">
      <div className="bg-white agreement-page">
        <div className="padding-top-24 font-size-16 font-weight-bold padding-h-16">
          Chọn chương trình muốn quyên góp
        </div>
        <div className="hide-scrollbar margin-top-12 padding-h-16 flex">
          {listCampaignAgreement.map((item) => (
            <button
              key={item.id}
              className={`button-amount padding-v-8 padding-h-16 button-campaign margin-right-10 flex-v-center ${item === campaign ? "active" : ""
                }`}
              onClick={() => clickCampaign(item)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-white padding-top-10">
        <DonateInput
          type_of_donation={type_of_donation}
          isDonateMonthly={true}
          isAgreementPay={false}
          amount={amount}
          message={message}
          bindingTerm={bindingTerm}
          setBindingTerm={setBindingTerm}
          onAmountChange={onAmountChange}
          onAmountPress={onAmountPress}
          onMessageChange={onMessageChange}
        />

        <div className="padding-h-16 flex-start">
          <Checkbox
            className="margin-top-24"
            onChange={onCheckChange}
            isChecking={isChecking}
            text="Ẩn tên và hình của bạn trong danh sách đóng góp."
          />
        </div>
      </div>
      {renderBottom()}
    </div>
  );
};

export default Agreement;

const styles = {
  borderRadius: {
    borderRadius: 12,
  },
};
