import { put, takeLatest } from 'redux-saga/effects';
import { AppApiInstance } from '../../api/app-api';
import { getCampaignDetail } from 'api/donationService';
import { types } from './campaign.type';

function* getCampaignDetailWatcher({ code }) {
  try {
    const data = yield getCampaignDetail(code);
    if (data && data.data) {
      yield put({ type: types.GET_CAMPAIGN_DETAIL_SUCCESS, campaign: data.data });
    } else {
      yield put({ type: types.GET_CAMPAIGN_DETAIL_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
    }
  }
  catch (err) {
    yield put({ type: types.GET_CAMPAIGN_DETAIL_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
  }
}

function* getMessages({ programId }) {
  try {
    const data = yield AppApiInstance.getMessages({ program_id: programId });
    if (data && data.data) {
      yield put({ type: types.GET_MESSAGES_SUCCESS, messages: data.data.length > 0 ? data.data : [] });
    } else {
      yield put({ type: types.GET_MESSAGES_FAIL });
    }
  }
  catch (err) {
    yield put({ type: types.GET_MESSAGES_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
  }
}

export function* campaignWatcher() {
  yield takeLatest(types.GETTING_CAMPAIGN_DETAIL, getCampaignDetailWatcher);
  yield takeLatest(types.GETTING_MESSAGES, getMessages);
}