import { types } from './campaign.type';

export const getCampaign = ({code}) => {
    return {
        type: types.GETTING_CAMPAIGN_DETAIL,
        code
    }
}

export const getMessages = ({programId}) => {
    return {
        type: types.GETTING_MESSAGES,
        programId
    }
}

export const setThanksModalVisible = (bool) => {
    return {
        type: types.SET_THANKS_MODAL_VISIBLE,
        bool
    }
}

export const clearCampaignErrMsg = () => {
    return {
        type: types.CLEAR_CAMPAIGN_ERROR_MSG,
    }
}