import React, {useState, useRef, useEffect} from "react";
import FloatInput from "components/FloatInput/FloatInput";
import Utils from "utils/utils";
import { useSelector } from "react-redux";
import { MAX_AMOUNT, MIN_AMOUNT, MAX_AMOUNT_AGREEMENT } from "utils/const";
import ModalRenew from "components/Modal/ModalRenew";
import { images } from "utils/images";
import ZaloPaySDK from "utils/zalopay";
import { tracking } from "utils/tracking";

const listAmount = [10000, 20000, 50000, 100000, 200000, 500000];


export const DonateInput = (props) => {
  const {
    onUnitPress,
    selectingUnit,
    receiveDonationUnits,
    amount,
    message,
    onAmountChange,
    onAmountPress,
    onMessageChange,
    isDonateMonthly,
    isAgreementPay,
    bindingTerm,
    setBindingTerm,
    type_of_donation,
  } = props;
  const [selectedMessage, setSelectedMessage] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { messages } = useSelector((state) => state.campaign);
  const [errMsg, setErrMsg] = useState("");
  const [isAmountInputFocused, setIsAmountInputFocused] = useState(false);
  const [displayAmount, setDisplayAmount] = useState("");

  const amountInputRef = useRef();
  const msgInputRef = useRef();


  useEffect(() => {
    setDisplayAmount(amount === "" ? "" : Utils.formatMoneyVND(amount));
  }, [amount])

  useEffect(() => {
    if(isAmountInputFocused === true) {
      setDisplayAmount(amount => amount.replace("đ", ""))
    } else {
      setDisplayAmount(amount => amount !== "" ? `${amount}đ` : "")
    }
  }, [isAmountInputFocused, amount])

  useEffect(() => {
    if (amount < MIN_AMOUNT) {
      setErrMsg(`Số tiền phải lớn hơn ${Utils.formatMoneyVND(MIN_AMOUNT)}đ`);
    } else {
      if (isDonateMonthly) {
        if (amount > MAX_AMOUNT_AGREEMENT) {
          setErrMsg(
              `Số tiền phải nhỏ hơn ${Utils.formatMoneyVND(
                  MAX_AMOUNT_AGREEMENT
              )}đ`
          );
        } else {
          setErrMsg("");
        }
      } else {
        if (amount > MAX_AMOUNT) {
          setErrMsg(
              `Số tiền phải nhỏ hơn ${Utils.formatMoneyVND(MAX_AMOUNT)}đ`
          );
        } else {
          setErrMsg("");
        }
      }
    }
  }, [amount, isDonateMonthly])

  const onChange = (value, e) => {
    value = value.replace(/[^0-9]/g, "").slice(0, 9).replace('đ', "");
    onAmountChange(value);
  };

  const onClickSuggestAmount = (value) => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.suggestAmount, {
      type_of_donation,
    });
    onAmountPress(value);
  };

  const onMessagePress = (value, index) => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.preMessage, {
      type_of_donation,
    });
    setSelectedMessage(index);
    onMessageChange(value);
  };

  const viewDetailBinding = () => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.editPage);
    setTimeout(() => {
      if (ZaloPaySDK.isZaloPay()) {
        ZaloPaySDK.launchDeeplink("zalopay://launch/app/731");
      } else {
        ZaloPaySDK.navigateTo(process.env.REACT_APP_ZPI_AGREEMENT_PAY_LIST);
      }
    }, 200);
  };

  const openModalTiming = () => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).donate.choseTerm);
    setIsOpenModal(true);
  };

  if (isAgreementPay && isDonateMonthly) {
    return (
      <>
        <div className="margin-top-16 padding-h-16">
          <img src={images.banner} alt="" className="w-100" />
        </div>
        <div className="font-size-16 text-align-center margin-top-20 padding-h-28 line-height-24">
          Bạn đã đăng ký quyên góp hàng tháng cho chương trình này. Đi đến trang
          quản lý để xem chi tiết và chỉnh sửa thông tin nếu muốn.
        </div>
        <div className="flex-h-center margin-top-24">
          <button
            onClick={viewDetailBinding}
            className="no-wrap padding-v-8 padding-h-14 border-radius-8 border-blue-1"
          >
            <div className="font-size-14 line-height-18 color-blue">
              Đi đến trang quản lý
            </div>
          </button>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="font-size-16 font-weight-bold padding-h-16">
        {isDonateMonthly
          ? "Chọn số  tiền quyên góp hàng tháng"
          : "Chọn số tiền quyên góp"}
      </div>
      <div className="margin-top-12 grid-columns-3 grid-gap-8 padding-h-16">
        {listAmount.map((item, index) => {
          return (
            <div
              onClick={() => onClickSuggestAmount(item)}
              className={`button-amount ${
                isDonateMonthly ? "padding-v-8" : "padding-v-14"
              } ${amount === item ? "active" : ""}`}
              key={index}
            >
              <div
                className={`column ${
                  isDonateMonthly ? "flex-start" : "flex-v-center"
                }`}
              >
                <div className="amount">{Utils.formatMoneyVND(item)}đ</div>
                {isDonateMonthly && <div className="text">mỗi tháng</div>}
              </div>
            </div>
          );
        })}
      </div>
      <div className="margin-top-16 padding-h-16">
        <div className="color-66798B font-size-14">Hoặc nhập số tiền khác</div>
        <FloatInput
          id="amount-input"
          ref={amountInputRef}
          placeholder="0đ"
          value={displayAmount}
          errMsg={errMsg}
          iconClear={true}
          textTransform={"capitalize"}
          onChangeText={onChange}
          inputStyle={styles.inputAmount}
          style={styles.wrapAmount}
          onBlur={() => setIsAmountInputFocused(false)}
          onFocus={() => setIsAmountInputFocused(true)}
          type={'tel'}
        />
      </div>
      {isDonateMonthly && (
        <>
          <div className="margin-top-16 padding-h-16 flex-space-between flex-v-center">
            <div className="color-001F3E font-size-14">Thời hạn quyên góp</div>
            <div className="select-box" onClick={openModalTiming}>
              <div className="label">{bindingTerm.label}</div>
              <img src={images.icon_dropdown} alt="" />
            </div>
          </div>
          <ModalRenew
            isOpen={isOpenModal}
            closeModal={() => setIsOpenModal(false)}
            bindingTerm={bindingTerm}
            setBindingTerm={setBindingTerm}
          />
        </>
      )}
      <div className="line margin-top-16"></div>
      <div className="padding-h-16">
        <div className="padding-top-16 font-size-16 font-weight-bold">
          Gửi lời nhắn tới chương trình
        </div>
        <FloatInput
          id="msg-input"
          ref={msgInputRef}
          placeholder="Nhập lời nhắn"
          value={message}
          iconClear={true}
          onChangeText={onMessageChange}
          inputStyle={styles.inputMsg}
          style={styles.wrapAmount}
          inputIcon={<img src={images.icon_message_grey} alt={""}/>}
        />
      </div>
      <div className="hide-scrollbar margin-top-12 grid-columns grid-gap-8 padding-h-16 overflow-scroll">
        {messages.map((item, index) => {
          return (
            <div
              onClick={() => onMessagePress(item.message, index)}
              className={"bg-blue-light no-wrap padding-v-10 padding-h-16 border-radius-8 " + (selectedMessage === index ? "color-blue-500 border-blue-500" : "color-dark-500")}
              key={index}
            >
              <div className="font font-size-14 line-height-18">
                {item.message}
              </div>
            </div>
          );
        })}
        <div style={{ width: 20 }} />
      </div>
      <div className="line margin-top-16" />
      <div className="padding-h-16">
        <div className="padding-top-16 font-size-16 font-weight-bold">
          Chọn đơn vị nhận quyên góp
        </div>
        <div className={"grid-columns-4 grid-gap-12 margin-top-8"}>
          {receiveDonationUnits?.map((unit, index) => {
            return (
              <button
                key={index}
                onClick={() => onUnitPress(unit)}
                className={`pos-relative bg-white ${
                  selectingUnit.id === unit.id
                    ? "border-blue-500"
                    : "border-blue-50"
                } border-radius-6 padding-h-10 padding-v-8`}
                style={{ height: 48 }}
              >
                {selectingUnit.id === unit.id && (
                  <img
                    alt="check"
                    className={"pos-absolute"}
                    src={images.icon_check}
                    style={{ top: 0, right: 0, width: 16, height: 16 }}
                  />
                )}
                <img
                  className={"fit-contain"}
                  alt="unit logo"
                  src={unit.img_path}
                  style={{ height: "100%", width: "100%" }}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapAmount: {
    marginTop: 10,
  },
  inputAmount: {
    fontSize: 24,
    padding: "12px 16px",
  },
  inputMsg: {
    fontSize: 16,
    padding: "16px",
  },
};
