import { types } from './order.type';

const initState = {
    creatingOrder: false,
    orderData: null,
    createOrderErrMsg: '',
}


export const orderReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATING_ORDER:
            return {
                ...state,
                creatingOrder: true,
                orderData: null,
                createOrderErrMsg: '',
            }
        case types.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                creatingOrder: false,
                orderData: action.orderData,
                createOrderErrMsg: '',
            };
        case types.CREATE_ORDER_FAIL:
            return {
                ...state,
                creatingOrder: false,
                orderData: null,
                createOrderErrMsg: action.errMsg,
            };
        case types.CLEAR_ORDER:
            return {
                ...state,
                creatingOrder: false,
                orderData: null,
                createOrderErrMsg: '',
            };
        default:
            return state;
    }
};