import ZaloPaySDK from "./zalopay";
import { DONATION_ENV, CAMPAIGN_SUMMARY_FOOTER } from "./const";
import dayjs from "dayjs";

export default class Utils {
  static formatMoneyVND = (p) => {
    return this.formatMoney(p, 0, "");
  }


  static formatMoney = (number, places, symbol, thousand, decimal) => {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ".";
    decimal = decimal || ",";

    let negative = number < 0 ? "-" : "";
    let i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "";
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "") + symbol;
  };


  static numToWords = (number) => {
    var digits = number.toString().length;

    if (digits > 9) {
      number /= (1000000000);
      return number.toFixed(1).replace(/\.?0+$/, '') + ' tỷ';
    }
    if (digits > 6) {
      number /= (1000000);
      return number.toFixed(1).replace(/\.?0+$/, '') + ' triệu';
    }
    return number.toString();
  }

  static isCampaignEnd = (endDate) => {
    const datetime = dayjs(Number(endDate)).valueOf();
    const now = dayjs().valueOf();

    if (now > datetime) {
      return true;
    }
    return false;
  }

  static getRemainDay = (date) => {
    const datetime = dayjs(Number(date)).valueOf();
    const now = dayjs().valueOf();
    if (dayjs() > datetime) {
      return 'Đã kết thúc'
    }

    // get total seconds between the times
    var delta = Math.abs(datetime - now) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = Math.floor(delta % 60);

    if (days === 0 && hours === 0 && minutes === 0) {
      return `Còn ${seconds} giây`;
    }

    if (days === 0 && hours === 0) {
      return `Còn ${minutes} phút`;
    }

    if (days === 0) {
      return `Còn ${hours} giờ`;
    }

    if (days > 90) {
      return `Còn 3 tháng`;
    }

    if (days > 60) {
      return `Còn 2 tháng`;
    }

    if (days > 30) {
      return `Còn 1 tháng`;
    }

    if (days <= 30 && days >= 1) {
      return `Còn ${days} ngày`;
    }

    return "Còn " + days + " ngày";
  }
  static getTimeDiff = (date, now, maxNoOfDays, isRoundUp = false) => {
    if (!date) {
      return '';
    }

    const dateObj = new Date(date.replace(/-/g, "/"));

    const datetime = dateObj.getTime();

    // get total seconds between the times
    var delta = Math.abs(now - datetime) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;

    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = delta % 60;
    if (days === 0 && hours === 0 && minutes === 0) {
      return Math.floor(seconds) + " giây trước"
    }

    if (days === 0 && hours === 0) {
      return minutes + (isRoundUp === true && (seconds / 60) >= 0.5 ? 1 : 0) + " phút trước"
    }
    if (days === 0) {
      return hours + (isRoundUp === true && (minutes / 60) >= 0.5 ? 1 : 0) + " giờ trước"
    }

    const roundUpDays = days + (isRoundUp === true && (hours / 24) >= 0.5 ? 1 : 0);

    if (maxNoOfDays !== undefined && roundUpDays >= maxNoOfDays) {
      return dateObj.toLocaleDateString('vi', { day: '2-digit', month: '2-digit', year: 'numeric' })
    }

    return roundUpDays + " ngày trước";
  }

  static getEnv = () => {
    let isZPI = ZaloPaySDK.isZPI();
    let isZaloPay = ZaloPaySDK.isZaloPay();
    let isAndroid = ZaloPaySDK.isAndroid();
    let isIOS = ZaloPaySDK.isIOS();
    let env = DONATION_ENV.ENV_WEB;

    if (isZaloPay) {
      if (isAndroid) {
        env = DONATION_ENV.ENV_ZLP_APP_ANDROID;
      }
      if (isIOS) {
        env = DONATION_ENV.ENV_ZLP_APP_ANDROID;
      }
    }
    if (isZPI) {
      if (isAndroid) {
        env = DONATION_ENV.ENV_ZLP_ZPI_ANDROID;
      }
      if (isIOS) {
        env = DONATION_ENV.ENV_ZLP_ZPI_IOS;
      }
    }
    return env;
  }

  static getCampaignFooterType = (campaign) => {
    if (Utils.isCampaignEnd(campaign.end_time)) {
      return CAMPAIGN_SUMMARY_FOOTER.RESULT
    }
    if (campaign.num_of_donations && campaign.num_of_donations > 0) {
      return CAMPAIGN_SUMMARY_FOOTER.LIST_DONOR
    }
    return CAMPAIGN_SUMMARY_FOOTER.NO_DONOR
  }
  static removeVietnameseTones = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.trim();
    return str;
  }
}
