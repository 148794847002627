import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { NODE_ENV } from "../utils/const";
import { appParamsReducer as appParams } from './appparams/appparams.reducer';
import { campaignReducer as campaign } from './../stores/campaign/campaign.reducer';
import { donationReducer as donation } from './../stores/donation/donation.reducer';
import { orderReducer as order } from './../stores/order/order.reducer';
import { listCampaignReducer as listCampaign } from './../stores/listcampaign/listcampaign.reducer';
import { resultReducer as result } from './../stores/result/result.reducer';

/* Reducers */
const reducers = {
  appParams,
  campaign,
  donation,
  order,
  listCampaign,
  result
};

const rootReducer = combineReducers(reducers);

/* Saga */
const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== NODE_ENV.PRODUCTION && typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
}

/* Create Store */
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

/* Run saga */
sagaMiddleware.run(rootSaga);