export const types = {
  GET_LATEST_PHILANTHROPISTS: 'GET_LATEST_PHILANTHROPISTS',
  GET_LATEST_PHILANTHROPISTS_SUCCESS: 'GET_LATEST_PHILANTHROPISTS_SUCCESS',
  GET_LATEST_PHILANTHROPISTS_FAILED: 'GET_LATEST_PHILANTHROPISTS_FAILED',

  GET_BEST_PHILANTHROPISTS: 'GET_BEST_PHILANTHROPISTS',
  GET_BEST_PHILANTHROPISTS_SUCCESS: 'GET_BEST_PHILANTHROPISTS_SUCCESS',
  GET_BEST_PHILANTHROPISTS_FAILED: 'GET_BEST_PHILANTHROPISTS_FAILED',

  GET_MY_PHILANTHROPISTS: 'GET_MY_PHILANTHROPISTS',
  GET_MY_PHILANTHROPISTS_SUCCESS: 'GET_MY_PHILANTHROPISTS_SUCCESS',
  GET_MY_PHILANTHROPISTS_FAILED: 'GET_MY_PHILANTHROPISTS_FAILED',

  GETTING_PHILANTHROPISTS: 'GETTING_PHILANTHROPISTS',
  GET_PHILANTHROPISTS_SUCCESS: 'GET_PHILANTHROPISTS_SUCCESS',
  GET_PHILANTHROPISTS_FAIL: 'GET_PHILANTHROPISTS_FAIL',

  GETTING_CONTRIBUTIONS: 'GETTING_CONTRIBUTIONS',
  GET_CONTRIBUTIONS_SUCCESS: 'GET_CONTRIBUTIONS_SUCCESS',
  GET_CONTRIBUTIONS_FAIL: 'GET_CONTRIBUTIONS_FAIL',

  GETTING_RANKING: 'GETTING_RANKING',
  GET_RANKING_SUCCESS: 'GET_RANKING_SUCCESS',
  GET_RANKING_FAIL: 'GET_RANKING_FAIL',
}