import { put, takeLatest } from 'redux-saga/effects';
import { types } from './donation.type';
import { store } from './../configStore';
import { getLatestDonations, getBestDonations, getMyDonations, getListDonationWithCampaign, getMyDonationWithCampaign } from "api/donationService";

function* getListPhilanthropists({ payload: { currentPage, campaignCode } }) {
  try {
    const { data } = yield getListDonationWithCampaign({ current_page: currentPage, campaign_code: campaignCode });
    if (data) {
      yield put({ type: types.GET_PHILANTHROPISTS_SUCCESS, philanthropists: data, currentPage });
    } else {
      yield put({ type: types.GET_PHILANTHROPISTS_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
    }
  }
  catch (err) {
    yield put({ type: types.GET_PHILANTHROPISTS_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
  }
}

function* getListContributions({ payload: { campaignCode } }) {
  const { userInfo } = store.getState().appParams;
  try {
    const { data, summary } = yield getMyDonationWithCampaign({ current_page: 0, campaign_code: campaignCode, user_id: userInfo.zalopay_userid });
    yield put({ type: types.GET_CONTRIBUTIONS_SUCCESS, payload: { data: data || [], totalContributions: summary?.total_number_of_donations, totalContributionsAmount: summary?.total_amount } });
  }
  catch (err) {
    yield put({ type: types.GET_CONTRIBUTIONS_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' });
  }
}

function* getLatestPhilanthropistsWatcher({ payload }) {
  const { currentPage } = payload;
  try {
    const { data } = yield getLatestDonations({ current_page: currentPage });
    if (data) {
      yield put({
        type: types.GET_LATEST_PHILANTHROPISTS_SUCCESS, payload: {
          data: data,
          currentPage,
        }
      });
    } else {
      yield put({ type: types.GET_LATEST_PHILANTHROPISTS_FAILED, payload: { errorMessage: data.message || 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
    }
  }
  catch (err) {
    yield put({ type: types.GET_LATEST_PHILANTHROPISTS_FAILED, payload: { errorMessage: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
  }
}

function* getBestPhilanthropistsWatcher({ payload }) {
  const { currentPage } = payload;
  try {
    const { data } = yield getBestDonations({ current_page: currentPage });
    if (data) {
      yield put({
        type: types.GET_BEST_PHILANTHROPISTS_SUCCESS, payload: {
          data: data,
          currentPage,
        }
      });
    } else {
      yield put({ type: types.GET_BEST_PHILANTHROPISTS_FAILED, payload: { errorMessage: data.message || 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
    }
  }
  catch (err) {
    yield put({ type: types.GET_BEST_PHILANTHROPISTS_FAILED, payload: { errorMessage: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
  }
}

function* getMyPhilanthropistsWatcher({ payload }) {
  try {
    const { userInfo } = store.getState().appParams;
    if (!userInfo?.zalopay_userid) {
      yield put({ type: types.GET_MY_PHILANTHROPISTS_FAILED, payload: { errorMessage: 'UserID could not be found!' } });
      return;
    }
    const { data } = yield getMyDonations(userInfo?.zalopay_userid);
    if (data) {
      yield put({ type: types.GET_MY_PHILANTHROPISTS_SUCCESS, payload: { data } });
    } else {
      yield put({ type: types.GET_MY_PHILANTHROPISTS_FAILED, payload: { errorMessage: data.message || 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
    }
  }
  catch (err) {
    yield put({ type: types.GET_MY_PHILANTHROPISTS_FAILED, payload: { errorMessage: 'Có lỗi xảy ra, xin vui lòng thử lại sau!' } });
  }
}

export function* donationWatcher() {
  yield takeLatest(types.GETTING_PHILANTHROPISTS, getListPhilanthropists);
  yield takeLatest(types.GETTING_CONTRIBUTIONS, getListContributions);
  yield takeLatest(types.GET_LATEST_PHILANTHROPISTS, getLatestPhilanthropistsWatcher);
  yield takeLatest(types.GET_BEST_PHILANTHROPISTS, getBestPhilanthropistsWatcher);
  yield takeLatest(types.GET_MY_PHILANTHROPISTS, getMyPhilanthropistsWatcher)

}