import { put, takeLatest } from 'redux-saga/effects';
import { AppApiInstance } from '../../api/app-api';
import { types } from './order.type';
import { store } from '../configStore';
import Utils from '../../utils/utils';

function* createOrder({params}) {
    const { userInfo } = store.getState().appParams;
    try {
        let createOrderParams = {
            ...params,
            "social_id": userInfo.mUid,
            // "user_name": "Lương Kiên Hào",
            // "avatar": "https://s120-ava-talk.zadn.vn/c/7/a/2/5/120/614e281f590123158d23b00b4d7ad47a.jpg",
            "env": Utils.getEnv(),
        }
        const data = yield AppApiInstance.createOrder(createOrderParams);
        if ((data && !data.code) || (data.code && data.code === 0)) {
            yield put({ type: types.CREATE_ORDER_SUCCESS, orderData: data });
        } else {
            yield put({ type: types.CREATE_ORDER_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!'});
        }
    }
    catch (err) {
        yield put({ type: types.CREATE_ORDER_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!'});
    }
}

export function* orderWatcher() {
    yield takeLatest(types.CREATING_ORDER, createOrder);
}