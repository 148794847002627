import axios from 'axios';
import { HTTP_ERROR } from "./error-code";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_DONATION_ENDPOINT,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

function resolve({
  data: axiosData,
  ...otherResponse
}) {
  return Promise.resolve(axiosData);
}


export function reject(error: any) {
  if (error.code === "ECONNABORTED") {
    return Promise.reject({ code: HTTP_ERROR.Timeout, axiosError: error });
  }
  if (error.response) {
    return Promise.reject({ code: HTTP_ERROR.ServerDown, axiosError: error });
  }
  if (error.request) {
    return Promise.reject({ code: HTTP_ERROR.NoResponse, axiosError: error });
  }
  return Promise.reject(new Error(error.message));
}
axiosInstance.interceptors.response.use(resolve, reject);

export function getListCampaigns({ current_page = 0, page_size = 20, status, }) {
  return axiosInstance.get("/programs", {
    params: {
      current_page: 0,
      page_size: page_size,
      status: status,
    }
  })
}

export function getCampaignDetail(code) {
  return axiosInstance.get(`/programs/${code}`)
}


export function getLatestDonations({ current_page, page_size = 10 }) {
  return axiosInstance.get("/philanthropists/newest", {
    params: {
      current_page: current_page,
      page_size: page_size,
    }
  })
}

export function getBestDonations({ current_page, page_size = 10 }) {
  return axiosInstance.get("/philanthropists/highest-total-donations", {
    params: {
      current_page: current_page,
      page_size: page_size,
    }
  })
}

export function getMyDonations(userID) {
  return axiosInstance.get("/philanthropists/personal", { params: { user_id: userID } })
}

export function getListDonationWithCampaign({
  campaign_code,
  current_page,
  page_size = 10
}) {
  return axiosInstance.get(`/programs/${campaign_code}/philanthropists/newest`, {
    params: {
      current_page,
      page_size,
    }
  })
}

export function getMyDonationWithCampaign({
  campaign_code,
  current_page,
  page_size = 10,
  user_id,
}) {
  return axiosInstance.get(`/programs/${campaign_code}/philanthropists/personal`, {
    params: {
      user_id,
      current_page,
      page_size,
    }
  })
}