import React, {useEffect} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import './loading-style.css';
import { images } from '../../utils/images';

const Loading = () => {

    useEffect(() => {
        window.onpopstate = function (event) {
            close()
        };
        return () => window.onpopstate = null;
    }, [])

    const close = () => {
        removeElementReconfirm()
    };

    return (
        <div className='react-confirm-alert-overlay'>
            <div className='loading-contain'>
                <div className="box-loading">
                    <div className="loading-logo">
                        <img src={images.logo_zalopay} alt="Logo Zalopay"/>
                    </div>
                    <div className="loading-dot">
                        <div className="lds-ellipsis">
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function createElementReconfirm(properties) {
    let divTarget = document.getElementById('react-confirm-alert')
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<Loading {...properties} />, divTarget)
    } else {
        // Mount the ReactConfirmAlert component
        document.body.children[0].classList.add('react-confirm-alert-blur')
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm-alert'
        document.body.appendChild(divTarget)
        render(<Loading {...properties} />, divTarget)
    }
}

function removeElementReconfirm() {
    const target = document.getElementById('react-confirm-alert');
    if (target && target.parentNode) {
        try {
            unmountComponentAtNode(target)
            target.parentNode.removeChild(target)   
        } catch (error) {
            
        }
    }
}

export function ShowLoading() {
    createElementReconfirm()
}

export function HideLoading() {
    removeElementReconfirm()
}