import { types } from './result.type';

const initState = {
    gettingResult: false,
    result: null,
    getResultErrMsg: '',
}


export const resultReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GETTING_RESULT:
            return {
                ...state,
                gettingResult: true,
                result: null,
                getResultErrMsg: '',
            }
        case types.GET_RESULT_SUCCESS:
            return {
                ...state,
                gettingResult: false,
                result: action.result,
                getResultErrMsg: '',
            };
        case types.GET_RESULT_FAIL:
            return {
                ...state,
                gettingResult: false,
                result: null,
                getResultErrMsg: action.errMsg,
            };
        case types.CLEAR_RESULT_ERR_MSG:
            return {
                ...state,
                getResultErrMsg: ''
            }
        default:
            return state;
    }
};