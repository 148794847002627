import { types } from './campaign.type'; 

const initState = {
    gettingCampaignDetail: false,
    campaign: null,
    getCampaignErrMsg: '',

    gettingMessage: false,
    messages: [],

    thanksModalVisible: '',
}


export const campaignReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GETTING_CAMPAIGN_DETAIL: 
            return {
                ...state,
                gettingCampaignDetail: true,
                campaign: null,
                getCampaignErrMsg: '',

            }
        case types.GET_CAMPAIGN_DETAIL_SUCCESS: 
            return { 
                ...state,
                gettingCampaignDetail: false,
                campaign: action.campaign,
                getCampaignErrMsg: '',
            };
        case types.GET_CAMPAIGN_DETAIL_FAIL: 
            return {
                ...state,
                gettingCampaignDetail: false,
                campaign: null,
                getCampaignErrMsg: action.errMsg,
            };

        case types.CLEAR_CAMPAIGN_ERROR_MSG: 
            return {
                ...state,
                getCampaignErrMsg: ''
            }

        case types.GETTING_MESSAGES:
            return {
                ...state,
                gettingMessage: true,
                messages: [],
            };
        case types.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                gettingMessage: false,
                messages: action.messages,
            };
        case types.GET_MESSAGES_FAIL:
            return {
                ...state,
                gettingMessage: false,
                messages: [],
            };

        case types.SET_THANKS_MODAL_VISIBLE:
            return {
                ...state,
                thanksModalVisible: action.bool
            }
        default:
            return state;
    }
};