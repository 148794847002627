import React, { useEffect } from "react";
import Modal from "react-modal";
import "./modal-renew.scss";
import { images } from "utils/images";
import { terms } from "utils/const";
import ZaloPaySDK from "utils/zalopay";
import { tracking } from "utils/tracking";

const addMonth = (month) => {
  const moment = new Date();
  const newMonth = moment.getMonth() + month;
  const newDate = moment.getDate();
  const newYear = moment.getFullYear();
  return new Date(newYear, newMonth, newDate);
};

const getFormattedDate = (date) => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return day + "/" + month + "/" + year;
};

const PickerItem = (props) => {
  const onCheckItem = (value, label) => {
    props.onCheckItem({ value, label });
  };

  const { item, selectedValue } = props;
  const isSelected = item.value === selectedValue.value;

  return (
    <div
      className={`term-item ${isSelected ? "term-item-selected" : ""}`}
      onClick={() => onCheckItem(item.value, item.label)}
    >
      <div className="text">
        <div className="term-item-label">{item.label}</div>
        {item.value !== 0 && (
          <div className="expected">
            Hết hạn dự kiến: {getFormattedDate(addMonth(item.value))}
          </div>
        )}
      </div>
      {isSelected && <img src={images.radio_button} alt="" />}
    </div>
  );
};

const ModalRenew = (props) => {
  const { isOpen, closeModal, bindingTerm, setBindingTerm } = props;

  useEffect(() => {
    if (isOpen) {
      ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).modalTime.loaded);
    }
  }, [isOpen]);

  const clickCloseModal = () => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).modalTime.close);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={clickCloseModal}
      style={modalStyles}
      ariaHideApp={false}
      closeTimeoutMS={300}
      className={{
        base: 'modal-renew',
        afterOpen: 'modal-renew--after-open',
        beforeClose: 'modal-renew--before-close'
      }}
    >
      <div className="modal-renew-title">
        <div className="flex-h-center title">Thời hạn quyên góp</div>
        <button className="btn-close-modal" onClick={clickCloseModal}>
          <img src={images.icon_close_popup} alt="icon clear" />
        </button>
      </div>
      <div className="term-list">
        {terms.map((item, index) => {
          return (
            <PickerItem
              key={index}
              index={index}
              length={terms.length}
              selectedValue={bindingTerm}
              item={item}
              onCheckItem={(value) => {
                ZaloPaySDK.tracking(
                  tracking(ZaloPaySDK.isZPI()).modalTime.outside,
                  { recurring_payment_period: value }
                );
                setBindingTerm(value);
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default React.memo(ModalRenew);

const modalStyles = {
  overlay: {
    position: "fixed",
    left: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  }
};
