import React, { useEffect } from "react";
import { SafeAreaView } from "components/SafeAreaView";
import ZaloPaySDK from "utils/zalopay";
import { Button } from "components/Button";
import { useHistory, useLocation } from "react-router-dom";
import { tracking } from "utils/tracking";

const TermAncConditions = () => {
  const history = useHistory();
  const { template } = useLocation().state;
  useEffect(() => {
    ZaloPaySDK.setTitle("VĂN BẢN THỎA THUẬN TÀI TRỢ");
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).TnCPage.loaded);
    const unListen = history.listen((newLocation, action) => {
      unListen();
      ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).TnCPage.goback);
    });
  }, [history]);
  return (
    <SafeAreaView>
      <div dangerouslySetInnerHTML={{ __html: template }}></div>
      <div className="font-size-14 padding-16 margin-top-24">
        <Button
          onPress={() => history.goBack()}
          btnStyle={styles.borderRadius}
          textClassName="font-weight-bold"
          text="Quay lại"
        />
      </div>
    </SafeAreaView>
  );
};

export default TermAncConditions;

const styles = {
  borderRadius: {
    borderRadius: 12,
  },
};
