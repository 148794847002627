const ApiPaths = {
  GET_LIST_CAMPAIGN: '/v1/program/list',
  GET_CAMPAIGN: '/v1/program/detail',
  GET_LIST_PHILANTHROPISTS: '/v1/program/list-philanthropists',
  GET_LIST_CONTRIBUTIONS: '/v1/program/contribution-by-personal',
  CREAT_ORDER: '/v1/order/create',
  GET_ORDER_DETAIL: '/v1/order/detail',
  GET_MESSAGES: '/v1/program/message',
  GET_RANKING: '/v1/program/donate-newest',
  CREAT_AGREEMENT: '/v1/agreement/bind',
  GET_AGREEMENT: '/v1/agreement/detail',
};

export {
  ApiPaths,
};
