import { types } from './donation.type';

const initState = {
  gettingPhilanthropists: false,
  philanthropists: [],
  getPhilanthropistsErrMsg: '',
  currentPagePhilanthropists: 0,

  gettingContributions: false,
  contributions: [],
  getContributionsErrMsg: '',
  totalContributions: 0,
  totalContributionsAmount: 0,

  latestDonations: {
    fetching: false,
    data: [],
    errorMessage: '',
    currentPage: 0,
  },
  bestDonations: {
    fetching: false,
    data: [],
    errorMessage: '',
    currentPage: 0,
  },
  myDonations: {
    fetching: false,
    data: [],
    errorMessage: '',
  }
}


export const donationReducer = (state = initState, action) => {
  switch (action.type) {
    //PHILANTHROPISTS
    case types.GETTING_PHILANTHROPISTS:
      return {
        ...state,
        gettingPhilanthropists: true,
        philanthropists: action.currentPage === 1 ? [] : state.philanthropists,
        getPhilanthropistsErrMsg: '',
      }
    case types.GET_PHILANTHROPISTS_SUCCESS:
      return {
        ...state,
        gettingPhilanthropists: false,
        philanthropists: [...state.philanthropists, ...action.philanthropists],
        getPhilanthropistsErrMsg: '',
        currentPagePhilanthropists: action.currentPage,
      };
    case types.GET_PHILANTHROPISTS_FAIL:
      return {
        ...state,
        gettingPhilanthropists: false,
        getPhilanthropistsErrMsg: action.errMsg,
      };

    //CONTRIBUTIONS
    case types.GETTING_CONTRIBUTIONS:
      return {
        ...state,
        gettingContributions: true,
        contributions: [],
        getContributionsErrMsg: '',
        totalContributions: 0,
        totalContributionsAmount: 0,
      }
    case types.GET_CONTRIBUTIONS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        gettingContributions: false,
        contributions: [...payload.data],
        getContributionsErrMsg: '',
        totalContributions: payload.totalContributions,
        totalContributionsAmount: payload.totalContributionsAmount,
      };
    }
    case types.GET_CONTRIBUTIONS_FAIL:
      return {
        ...state,
        gettingContributions: false,
        contributions: [],
        getContributionsErrMsg: action.errMsg,
        totalContributions: 0,
        totalContributionsAmount: 0,
      };

    case types.GET_LATEST_PHILANTHROPISTS: {
      return {
        ...state,
        latestDonations: {
          ...state.latestDonations,
          fetching: true,
          errorMessage: '',
        }
      }
    }
    case types.GET_LATEST_PHILANTHROPISTS_SUCCESS: {
      return {
        ...state,
        latestDonations: {
          ...state.latestDonations,
          fetching: false,
          data: [...state.latestDonations.data, ...(action.payload?.data || [])],
          errorMessage: '',
          currentPage: action.payload.currentPage,
        }
      }
    }
    case types.GET_LATEST_PHILANTHROPISTS_FAILED: {
      return {
        ...state,
        latestDonations: {
          ...state.latestDonations,
          fetching: false,
          errorMessage: action.payload.errorMessage,
        }
      }
    }
    case types.GET_BEST_PHILANTHROPISTS: {
      return {
        ...state,
        bestDonations: {
          ...state.bestDonations,
          fetching: true,
          errorMessage: '',
        }
      }
    }
    case types.GET_BEST_PHILANTHROPISTS_SUCCESS: {
      return {
        ...state,
        bestDonations: {
          ...state.bestDonations,
          fetching: false,
          data: [...state.bestDonations.data, ...(action.payload?.data || [])],
          errorMessage: '',
          currentPage: action.payload.currentPage,
        }
      }
    }
    case types.GET_BEST_PHILANTHROPISTS_FAILED: {
      return {
        ...state,
        bestDonations: {
          ...state.bestDonations,
          fetching: false,
          errorMessage: action.payload.errorMessage,
        }
      }
    }
    case types.GET_MY_PHILANTHROPISTS: {
      return {
        ...state,
        myDonations: {
          ...state.myDonations,
          fetching: true,
          errorMessage: '',
        }
      }
    }
    case types.GET_MY_PHILANTHROPISTS_SUCCESS: {
      return {
        ...state,
        myDonations: {
          ...state.myDonations,
          fetching: false,
          data: [...state.myDonations.data, ...(action.payload?.data || [])],
          errorMessage: '',
        }
      }
    }
    case types.GET_MY_PHILANTHROPISTS_FAILED: {
      return {
        ...state,
        myDonations: {
          ...state.myDonations,
          fetching: false,
          errorMessage: action.payload.errorMessage,
        }
      }
    }
    default:
      return state;
  }
};
