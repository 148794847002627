export const tracking = (isZPI = false) => {
    return {
        home: {
            loaded: isZPI ? '02.4901.000' : '01.4901.000',
            fullCardDonation: isZPI ? '02.4901.001' : '01.4901.001',
            miniCardDonation: isZPI ? '02.4901.002' : '01.4901.002',
            slide: isZPI ? '02.4901.003' : '01.4901.003',
            viewAll: isZPI ? '02.4901.004' : '01.4901.004',
            scrollToBottom: isZPI ? '02.4901.005' : '01.4901.005',
            viewMore: isZPI ? '02.4901.006' : '01.4901.006',
        },
        listDonation: {
            loaded: isZPI ? '02.4902.000' : '01.4902.000',
            search: isZPI ? '02.4902.001' : '01.4902.001',
            fullCardDonation: isZPI ? '02.4902.002' : '01.4902.002',
            donate: isZPI ? '02.4902.003' : '01.4902.003',
            result: isZPI ? '02.4902.004' : '01.4902.004',
            scrollToBottom: isZPI ? '02.4902.005' : '01.4902.005',
            noResult: isZPI ? '02.4902.006' : '01.4902.006',
        },
        detail: {
            loaded: isZPI ? '02.4903.000' : '01.4903.000',
            share: isZPI ? '02.4903.001' : '01.4903.001',
            donate: isZPI ? '02.4903.002' : '01.4903.002',
            donateBottom: isZPI ? '02.4903.003' : '01.4903.003',
            campaignDetail: isZPI ? '02.4903.004' : '01.4903.004',
            organizeUnit: isZPI ? '02.4903.005' : '01.4903.005',
            receiveDonationUnit: isZPI ? '02.4903.006' : '01.4903.006',
            philanthropistTab: isZPI ? '02.4903.007' : '01.4903.007',
            contributionTab: isZPI ? '02.4903.008' : '01.4903.008',
            //02.4903.009
            donateNowPhilanthropist: isZPI ? '02.4903.010' : '01.4903.010',
            donateNowContribution: isZPI ? '02.4903.011' : '01.4903.011',
            viewMoreMessage: isZPI ? '02.4903.012' : '01.4903.012',
            viewMoreDonations: isZPI ? '02.4903.013' : '01.4903.013',
            result: isZPI ? '02.4903.014' : '01.4903.014',
            resultBottom: isZPI ? '02.4903.015' : '01.4903.015',
        },
        donate: {
            loaded: isZPI ? '02.4904.000' : '01.4904.000',
            avatar: isZPI ? '02.4904.001' : '01.4904.001',
            amount: isZPI ? '02.4904.002' : '01.4904.002',
            message: isZPI ? '02.4904.003' : '01.4904.003',
            receiveDonationUnit: isZPI ? '02.4904.004' : '01.4904.004',
            clickHideInfo: isZPI ? '02.4904.005' : '01.4904.005',
            unClickHideInfo: isZPI ? '02.4904.006' : '01.4904.006',
            next: isZPI ? '02.4904.007' : '01.4904.007',
            preAmount: isZPI ? '02.4904.008' : '01.4904.008',
            preMessage: isZPI ? '02.4904.009' : '01.4904.009',
            suggestAmount: isZPI ? '02.4904.010' : '01.4904.010',
            readTnC: isZPI ? '02.4904.011' : '01.4904.011',
            goBack: isZPI ? '02.4904.012' : '01.4904.012',
            donateOneTime: isZPI ? '02.4904.013' : '01.4904.013',
            choseTerm: isZPI ? '02.4904.014' : '01.4904.014',
            editPage: isZPI ? '02.4904.015' : '01.4904.015',
            donateMonthly: isZPI ? '02.4904.016' : '01.4904.016',
            clickCampaign: isZPI ? '02.4904.017' : '01.4904.017',
        },
        thanksModal: {
            loaded: isZPI ? '02.4905.000' : '01.4905.000',
            close: isZPI ? '02.4905.001' : '01.4905.001',
            outside: isZPI ? '02.4905.002' : '01.4905.002',
        },
        result: {
            loaded: isZPI ? '02.4906.000' : '01.4906.000',
            scrollDown: isZPI ? '02.4906.001' : '01.4906.001',
            //02.4906.002
            viewOther: isZPI ? '02.4906.003' : '01.4906.003',
        },
        modal: {
            loaded: isZPI ? '02.4907.000' : '01.4907.000',
            scrollDown: isZPI ? '02.4907.001' : '01.4907.001',
            close: isZPI ? '02.4907.002' : '01.4907.002',
            outside: isZPI ? '02.4907.003' : '01.4907.003',
        },
        modalTime: {
            loaded: isZPI ? '02.4908.000' : '01.4908.000',
            close: isZPI ? '02.4908.001' : '01.4908.001',
            outside: isZPI ? '02.4908.002' : '01.4908.002',
        },
        TnCPage: {
            loaded: isZPI ? '02.4909.000' : '01.4909.000',
            goback: isZPI ? '02.4909.001' : '01.4909.001',
        },
        monthlyTnCPage: {
            loaded: isZPI ? '02.4910.000' : '01.4910.000',
            readTnc: isZPI ? '02.4910.001' : '01.4910.001',
            continue: isZPI ? '02.4910.002' : '01.4910.002',
            goback: isZPI ? '02.4910.003' : '01.4910.003',
        },
    }
}