import BaseApi from "./base-api";
import { ApiPaths } from "./const";
import { PAGE_SIZE } from "../utils/const";

class AppApi extends BaseApi {
  createOrder(params) {
    return this.request(ApiPaths.CREAT_ORDER, params, null, "POST");
  }
  getMessages({ program_id }) {
    return this.request(ApiPaths.GET_MESSAGES, { program_id }, null, "GET");
  }
  getOrderDetail({ order_no }) {
    return this.request(ApiPaths.GET_ORDER_DETAIL, null, order_no, "GET");
  }
  getRanking({ current_page, page_size = PAGE_SIZE }) {
    return this.request(
      ApiPaths.GET_RANKING,
      { current_page, page_size },
      null,
      "GET"
    );
  }
  getResult({ code }) {
    return this.request(ApiPaths.GET_RESULT, null, code, "GET");
  }
  getAgreement(params) {
    return this.request(ApiPaths.GET_AGREEMENT, params, null, "POST");
  }
  createAgreement(params) {
    return this.request(ApiPaths.CREAT_AGREEMENT, params, null, "POST");
  }
}

const AppApiInstance = new AppApi();

export { BaseApi, AppApiInstance };
