import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ZaloPaySDK from '../../utils/zalopay';
import { getResult, clearResultErrMsg } from '../../stores/result/result.action';
import { Button } from '../../components/Button';
import { tracking } from '../../utils/tracking';
import { SafeAreaView } from '../../components/SafeAreaView';

const ResultScreen = () => {
    const history = useHistory();
    const { code } = useParams();
    const { gettingResult, result, getResultErrMsg } = useSelector(state => state.result);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResult({ code }));
    }, [code, dispatch])

    useEffect(() => {
        ZaloPaySDK.setTitle('Kết quả chương trình');
        ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).result.loaded);
        if (ZaloPaySDK.isIOS()) {
            window.scrollTo(0, 0);
        }
    }, [])

    useEffect(() => {
        document.body.style.backgroundColor = '#F5F9FF'
    }, [])

    useEffect(() => {
        if (gettingResult) {
            ZaloPaySDK.showLoading();
        } else {
            ZaloPaySDK.hideLoading();
        }
    }, [gettingResult])

    useEffect(() => {
        if (getResultErrMsg) {
            ZaloPaySDK.showDialog({ message: getResultErrMsg, buttons: [{ text: 'Đóng' }] })
        }
        return () => dispatch(clearResultErrMsg());
    }, [getResultErrMsg, dispatch])

    const onViewCampaignPress = () => {
        ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).result.viewOther);
        history.push('/');
    }

    if (!result) {
        return <div></div>
    }

    let preScroll = 0;
    const onScroll = (e) => {
        let el = document.getElementById('div');
        if (el && (preScroll < el.scrollTop)) {
            ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).result.scrollDown);
        }
        preScroll = el.scrollTop;
    }

    return (
        <SafeAreaView id={'div'} style={{overflow: 'scroll', height: '100vh'}} onScroll={onScroll} >
            <div className={'padding-16 padding-bottom-76'}>
                <div dangerouslySetInnerHTML={{__html: result}} style={{width: '100%'}} className={'break-word font font-size-16 line-height-20 color-black pre-line'}/>
                <div className={'bg padding-16 pos-fixed'} style={{left: 0, right: 0, bottom: 0}}>
                    <Button onPress={onViewCampaignPress} btnStyle={{borderRadius: 4}} textClassName={'font-weight-bold'} text={'Xem chương trình khác'} />
                </div>
            </div>
        </SafeAreaView>
    )
}

export default ResultScreen;
