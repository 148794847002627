class ApiManager {
  async request(apiPath, params = {}, pathParams = {}, method = 'POST') {
    // const { muid, maccesstoken } = ZaloPaySDK.getUserInfo();

    const headers = {
      'Content-Type': 'application/json',
      // "m_uid": muid,
      // "m_access_token": maccesstoken,
    };

    const domain = process.env.REACT_APP_API_ENDPOINT;
    
    let url = domain +  apiPath;
    if (method === 'GET' && pathParams) {
      url = `${url}/${pathParams}`
    }
    if (method === 'GET' && params) {
      url = new URL(url);
      Object.keys(params).forEach(key => {
        if (params[key] !== null && params[key] !== undefined){
          if (Array.isArray(params[key])) {
            return params[key].map((value) => url.searchParams.append(key, value))
          }
          return url.searchParams.append(key, params[key])
        }
      })
    }
    var body =  method === 'GET' ? undefined : JSON.stringify(params);
    return fetch(url, {
      method: method, 
      headers: headers,
      body: body,
    })
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        return ({ ...resp })
      })
      .catch(error => {
          Promise.reject({ ...error, code: parseInt(error.code) })
        }
      );
  }
}
export default ApiManager;
