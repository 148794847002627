export const APPID = 906;
export const NODE_ENV = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const DONATION_ENV = {
  ENV_UNKNOWN: 0,
  ENV_ZLP_APP_IOS: 1,
  ENV_ZLP_APP_ANDROID: 2,
  ENV_ZLP_ZPI_IOS: 3,
  ENV_ZLP_ZPI_ANDROID: 4,
  ENV_WEB: 5,
};

export const DONATION_INCOGNITO = {
  INCOGNITO_UNKNOWN: 0,
  INCOGNITO_HIDE: 1,
  INCOGNITO_SHOW: 2,
};

export const CAMPAIGN_SUMMARY_FOOTER = {
  NONE: 0,
  LIST_DONOR: 1,
  NO_DONOR: 2,
  RESULT: 3,
};

export const PAGE_SIZE = 10;

export const MIN_AMOUNT = 5000;
export const MAX_AMOUNT = 100000000;
export const MAX_AMOUNT_AGREEMENT = 20000000;

export const DONATION_SHOW_SHARE_PARTNER = {
  HIDE: 0,
  SHOW: 1,
};

export const DONATION_TAB = {
  PHILANTHROPIST_TAB: 0,
  CONTRIBUTION_TAB: 1,
};

export const terms = [
  {
    value: 3,
    label: "3 tháng",
  },
  {
    value: 6,
    label: "6 tháng",
  },
  {
    value: 12,
    label: "12 tháng",
  },
  {
    value: 0,
    label: "Vô thời hạn",
  },
];

export const AGREEMENT_STATUS = {
  CONFIRMED: "Confirmed",
  DISABLED: "Disabled",
};

export const RANKING_TAB_KEY = {
  Latest: "LATEST",
  All: "ALL",
  MyDonation: "MY_DONATION",
}
