export const images = {
  zalopay_notify: '/images/zalopay-notify.svg',
  logo_zalopay: '/images/logo-zalopay.svg',
  artwork_love: '/images/love.svg',
  default_avatar: '/images/default-avatar.svg',
  default_logo: '/images/logo-default.svg',

  modal_thanks_background: '/images/thanks-background.svg',
  modal_thanks_heart_1: '/images/heart-1.svg',
  modal_thanks_heart_2: '/images/heart-2.svg',
  modal_thanks_heart_box: '/images/heart-box.svg',

  medal_gold: '/images/medal-gold.svg',
  medal_silver: '/images/medal-silver.svg',
  medal_bronze: '/images/medal-bronze.svg',

  search_null: '/images/search-null.svg',

  icon_group: '/images/group.svg',
  icon_time: '/images/time.svg',
  icon_hide_popup: '/images/hide-popup.svg',
  icon_message: '/images/message.svg',
  icon_close_text: '/images/close-text.svg',
  icon_check: '/images/check.svg',
  icon_checkbox_inactive: '/images/checkbox-inactive.svg',
  icon_checkbox_active: '/images/checkbox-active.svg',
  icon_love_avatar: '/images/love-avatar.svg',
  icon_quote: '/images/quote.svg',
  icon_filter: '/images/filter.svg',
  icon_search: '/images/icon-search.svg',
  icon_close_popup: '/images/icon_hide_popup.svg',
  radio_button: '/images/radio-button.svg',
  icon_dropdown: '/images/icon_dropdown.svg',
  icon_check_done: '/images/icon-check.svg',
  bubble: '/images/bubble.png',
  home_background: '/images/home.svg',
  donor_badge: '/images/donor-badge.svg',
  donor_table_icon: '/images/donor-table-icon.svg',
  banner: '/images/banner.svg',
  update: '/images/update.png',
  thanks_banner: '/images/thanks-banner.svg',
  icon_crown: '/images/icon-crown.svg',
  icon_arrow_right: '/images/icon-arrow-right.svg',
  icon_share: '/images/icon-share.svg',
  empty_donor_list: '/images/empty-donor-list.svg',
  icon_close_modal: '/images/icon-close-modal.svg',
  internal_error: '/images/internal-error.svg',
  icon_clear_text: '/images/icon-clear-text.svg',
  icon_message_grey: '/images/message-grey.svg',
  leaderboard_background_third: '/images/donation_third.svg',
  leaderboard_background_second: '/images/donation_second.svg',
  leaderboard_background_first: '/images/donation_first.png',
  leaderboard_ribbon_third: '/images/ribbon_third.svg',
  leaderboard_ribbon_second: '/images/ribbon_second.svg',
  leaderboard_ribbon_first: '/images/ribbon_first.svg',
  leaderboard_rank_third: '/images/rank_third.png',
  leaderboard_rank_second: '/images/rank_second.png',
  leaderboard_rank_first: '/images/rank_first.png',
  avatar_annonymous_first: '/images/annonymous_first.png',
  avatar_annonymous_second: '/images/annonymous_second.png',
  avatar_annonymous_third: '/images/annonymous_third.png',
  back_of_envelop: '/images/back-envelop.svg',
  front_of_envelop: '/images/front-envelop.svg',
  stamp_border: '/images/stamp_border.svg',
  toro_stamp: '/images/toro-stamp.svg',
  heart: '/images/heart.svg',
  confetti_background: '/images/confetti-background.svg',
  letter_pattern: '/images/letter-pattern.svg',next_button: '/images/next-button.svg',
}

export const getAnnonymousAvatar = (index) => {
  if (index % 3 === 0) {
    return images.avatar_annonymous_first;
  }
  if (index % 3 === 1) {
    return images.avatar_annonymous_second;
  }
  return images.avatar_annonymous_third;
}