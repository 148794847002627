import React, { useEffect, useCallback } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import DetailScreen from "screens/detail/loadable";
import DonateScreen from "screens/donate/loadable";
import HomeScreen from "screens/home/loadable";
import Agreement from "screens/agreement/Agreement";
import ResultScreen from "screens/result/ResultScreen";
import { useDispatch, useSelector } from "react-redux";
import SearchScreen from "screens/search/loadable";
import About from "screens/about";
import TermAncConditions from "screens/terms-conditions";
import { getListCampaign } from "./stores/listcampaign/listcampaign.action";
import ZaloPaySDK from "./utils/zalopay";
import { setThanksModalVisible } from "./stores/campaign/campaign.action";
import { AppApiInstance } from "./api/app-api";
import { updateUserInfo } from "./stores/appparams/appparams.action";

const history = createBrowserHistory();

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code") || undefined;
  const screen = urlParams.get("screen") || undefined;
  const status = urlParams.get("status") || undefined;
  const appid = urlParams.get("appid") || undefined;
  const apptransid = urlParams.get("apptransid") || undefined;
  const appTransID = urlParams.get("appTransID") || undefined;
  const amount = urlParams.get("amount") || undefined;
  const view = urlParams.get("view") || undefined;
  const { loading, allCampaigns } = useSelector(
    (state) => state.listCampaign
  );
  const { campaign } = useSelector((state) => state.campaign);
  const { userInfo } = useSelector((state) => state.appParams);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ZaloPaySDK.isMobileDevice()) {
      if (ZaloPaySDK.isZaloPay()) {
        window.location.replace(`${process.env.REACT_APP_ZPA_LINK}${window.location.pathname}?${urlParams.toString()}`);
        return;
      }
      if (process.env.REACT_APP_ENV === "dev") {
        urlParams.set("zpi_env", "DEVELOPMENT");
      }
      window.location.replace(`https://zalo.me/s/3888182390069527250/donation${window.location.pathname}?${urlParams.toString()}`);
    }
  }, [urlParams])

  const getOrderdetail = useCallback(
    ({ apptransid }) => {
      AppApiInstance.getOrderDetail({ order_no: apptransid }).then((resp) => {
        dispatch(
          updateUserInfo({ displayname: resp.userName, avatarurl: resp.avatar })
        );
        if (resp.userName && resp.avatar && campaign && campaign.name) {
          dispatch(setThanksModalVisible(true));
        } else {
          dispatch(setThanksModalVisible(false));
        }
      });
    },
    [campaign, dispatch]
  );

  useEffect(() => {
    dispatch(getListCampaign());
  }, [dispatch]);

  useEffect(() => {
    if (status && appid && apptransid && amount) {
      if (status === "1") {
        getOrderdetail({ apptransid: apptransid });
      }
    } else {
      if (appTransID) {
        getOrderdetail({ apptransid: appTransID });
      }
    }
    if (!userInfo.displayName || !userInfo.avatarUrl) {
      dispatch(setThanksModalVisible(false));
    }
  }, [
    status,
    appid,
    apptransid,
    appTransID,
    amount,
    campaign,
    userInfo,
    getOrderdetail,
    dispatch,
  ]);

  useEffect(() => {
    if (loading) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [loading]);

  return (
    <div>
      <Router history={history}>
        <Switch>
          <Route path="/donate/:code" children={<DonateScreen />} />
          <Route path="/detail/:code" children={<DetailScreen />} />
          <Route path="/search" children={<SearchScreen />} />
          <Route path="/result/:code" children={<ResultScreen />} />
          <Route path="/tc" children={<TermAncConditions />} />
          <Route path="/about" children={<About />} />
          <Route path="/agreement" children={<Agreement />} />
          {allCampaigns.length === 1 && (
            <Redirect to={`/detail/${allCampaigns[0].code}`} />
          )}
          {view === "agreement" && <Redirect to="/agreement" />}
          {view === "detail" && <Redirect to={`/detail/${code}`} />}
          {view === "donate" && <Redirect to={`/donate/${code}`} />}
          {screen && screen !== "undefined" && code && code !== "undefined" ? (
            <Redirect to={`/${screen}/${code}`} />
          ) : (
            <Route path={"/"} children={<HomeScreen />} />
          )}
          <Route path="/" children={<HomeScreen />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
