import { put, takeLatest } from 'redux-saga/effects';
import { AppApiInstance } from '../../api/app-api';
import { types } from './result.type';

function* getResult({code}) {
    try {
        const data = yield AppApiInstance.getResult({code});
        if (data && data.result) {
            yield put({ type: types.GET_RESULT_SUCCESS, result: data.result });
        } else {
            yield put({ type: types.GET_RESULT_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!'});
        }
    }
    catch (err) {
        yield put({ type: types.GET_RESULT_FAIL, errMsg: 'Có lỗi xảy ra, xin vui lòng thử lại sau!'});
    }
}

export function* resultWatcher() {
    yield takeLatest(types.GETTING_RESULT, getResult);
}