import { types } from './result.type';

export const getResult = ({code}) => {
    return {
        type: types.GETTING_RESULT,
        code
    }
}


export const clearResultErrMsg = () => {
    return {
        type: types.CLEAR_RESULT_ERR_MSG,
    }
}