import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './stores/configStore';
import { Provider } from 'react-redux';
import './css/index.scss';
import { APPID } from './utils/const';
import ZaloPaySDK from './utils/zalopay';

export const Root = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

ZaloPaySDK.getAppUserInfo(APPID, function () {
  ReactDOM.render(<Root />, document.getElementById('root'));
});
