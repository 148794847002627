export const types = {
    GETTING_CAMPAIGN_DETAIL: 'GETTING_CAMPAIGN_DETAIL',
    GET_CAMPAIGN_DETAIL_SUCCESS: 'GET_CAMPAIGN_DETAIL_SUCCESS',
    GET_CAMPAIGN_DETAIL_FAIL: 'GET_CAMPAIGN_DETAIL_FAIL',
    CLEAR_CAMPAIGN_ERROR_MSG: 'CLEAR_CAMPAIGN_ERROR_MSG',
    
    GETTING_MESSAGES: 'GETTING_MESSAGES',
    GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
    GET_MESSAGES_FAIL: 'GET_MESSAGES_FAIL',

    SET_THANKS_MODAL_VISIBLE: 'SET_THANKS_MODAL_VISIBLE'
}
