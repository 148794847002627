import React, { useEffect } from "react";
import { SafeAreaView } from "components/SafeAreaView";
import ZaloPaySDK from "utils/zalopay";
import { images } from "utils/images";
import { Button } from "components/Button";
import { useHistory, useLocation } from "react-router-dom";
import { AppApiInstance } from "api/app-api";
import { tracking } from "utils/tracking";

const About = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    ZaloPaySDK.setTitle("Quyên góp");
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).monthlyTnCPage.loaded);
  }, []);
  useEffect(() => {
    const unListen = history.listen((newLocation, action) => {
      if (action === "POP") {
        ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).monthlyTnCPage.goBack);
      }
    });
    return () => {
      setTimeout(() => {
        unListen();
      }, 200);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const createAgreement = () => {
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).monthlyTnCPage.continue);
    const { agreementParams } = location.state;
    AppApiInstance.createAgreement(agreementParams).then((result) => {
      if (ZaloPaySDK.isZaloPay()) {
        ZaloPaySDK.launchDeeplink(result.deepLink);
      } else {
        const queryParam = {
          binding_token: result.bindingToken,
        };
        ZaloPaySDK.navigateTo(
          process.env.REACT_APP_ZPI_AGREEMENT_PAY +
            new URLSearchParams(queryParam)
        );
      }
      history.push("/");
    });
  };
  const readTnC = () => {
    const { template } = location.state;
    ZaloPaySDK.tracking(tracking(ZaloPaySDK.isZPI()).monthlyTnCPage.readTnc);
    setTimeout(() => {
      history.push("/tc", { template });
    }, 200);
  };
  return (
    <SafeAreaView>
      <div className="margin-top-16 padding-h-16">
        <img src={images.banner} alt="" className="w-100" />
      </div>
      <div className="font-size-18 font-weight-bold margin-top-10 padding-h-16">
        Bạn sẽ quyên góp hàng tháng như sau:
      </div>
      <div className="flex margin-top-16 padding-h-16">
        <img src={images.icon_check_done} alt="" className="w-16 h-16" />
        <div className="font-size-14 margin-left-8">
          Tháng đầu tiên được trừ ngay sau khi đăng ký.
        </div>
      </div>
      <div className="flex margin-top-16 padding-h-16">
        <img src={images.icon_check_done} alt="" className="w-16 h-16" />
        <div className="font-size-14 margin-left-8">
          Các tháng tiếp theo được tự động trừ vào ngày đăng ký.
        </div>
      </div>
      <div className="flex margin-top-16 padding-h-16">
        <img src={images.icon_check_done} alt="" className="w-16 h-16" />
        <div className="font-size-14 margin-left-8">
          Nếu bạn không đủ tiền, hệ thống chỉ trừ thêm 1 lần vào ngày kế tiếp.
        </div>
      </div>
      <div className="flex margin-top-16 padding-h-16">
        <img src={images.icon_check_done} alt="" className="w-16 h-16" />
        <div className="font-size-14 margin-left-8">
          Tự động ngừng quyên góp nếu chương trình kết thúc sớm.
        </div>
      </div>
      <div className="flex margin-top-16 padding-h-16 margin-bottom-170">
        <img src={images.icon_check_done} alt="" className="w-16 h-16" />
        <div className="font-size-14 margin-left-8">
          Ngừng quyên góp bất cứ lúc nào tại mục:
          <div>Cá nhân &gt; Thanh toán tự động.</div>
        </div>
      </div>
      <div className="pos position-fixed-bottom w-100 bg-shadow">
        <div className="padding-16">
          <div className="padding-h-18 font-size-14 margin-bottom-24 text-align-center">
            Bằng việc chọn Tiếp tục, bạn chấp thuận tài trợ cho chương trình và
            &nbsp;
            <span className="color-blue text-underline" onClick={readTnC}>
              Văn bản tiếp nhận tài trợ.
            </span>
          </div>
          <Button
            onPress={createAgreement}
            btnStyle={styles.borderRadius}
            textClassName="font-weight-bold"
            text="Tiếp tục"
          />
        </div>
      </div>
    </SafeAreaView>
  );
};

export default About;

const styles = {
  borderRadius: {
    borderRadius: 12,
  },
};
