import React, { useState } from "react";
import "./FloatInput.scss";
import { images } from "utils/images";

const FloatInput = React.forwardRef(
  (
    {
      style,
      inputStyle,
      value,
      type,
      id,
      errMsg,
      onChangeText,
      onSubmitEditing,
      disabled,
      placeholder,
      iconClear = true,
      onFocus = () => undefined,
      onBlur = () => undefined,
      inputIcon
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const onChange = (e) => {
        let text = e.target.value;
        return onChangeText && onChangeText(text, e);
    };

    const onKeyPress = (e) => {
      if (e.key === "Enter") {
        return onSubmitEditing && onSubmitEditing();
      } else {
        let text = e.target.value;
        return onChangeText && onChangeText(text);
      }
    };

    const onClearValue = () => {
      ref.current.focus();
      return onChangeText && onChangeText("");
    };

    const onFocusAction = (e) => {
      onFocus();
      setIsFocus(true);
    };
    const onBlurAction = (e) => {
      onBlur();
      setIsFocus(false);
    };

    return (
      <>
        <div
          style={style}
          className={`input-container ${
            isFocus ? "input-container-focus" : ""
          }`}
        >
          <input
            className="float-input"
            ref={ref}
            id={id}
            style={inputStyle}
            required
            autoComplete="off"
            type={type === "tel" ? "tel" : "text"}
            value={value}
            disabled={disabled}
            onKeyUp={onKeyPress}
            onChange={onChange}
            onFocus={onFocusAction}
            onBlur={onBlurAction}
            placeholder={placeholder}
          />
          <div className={'flex-center margin-right-18'}>
              {value && iconClear && !disabled ? (
                  <img style={{marginRight: inputIcon ? 12 : 0}} onClick={onClearValue} className="icon-clear" width={20} height={20} src={images.icon_clear_text} alt="icon clear" />
              ) : null}
              {inputIcon && value && iconClear && <div className={'icon-divider'}/>}
              {inputIcon}
          </div>
        </div>
        {errMsg && <div className="error-msg">{errMsg}</div>}
      </>
    );
  }
);

export default FloatInput;
