export const HTTP_ERROR = {
  Timeout: "Timeout",
  ServerDown: "ServerDown",
  NoResponse: "NoResponse"
}

const ApiCodes = {
  SUCCESS: 1,
  FAIL: 2,
  BAD_GATEWAY: 502,
  API_VERIFY_TOKEN_FAILED: -405,
  GET_USER_INFO_FAIL: -10000,
};

const DEFAULT_MESSAGE = 'Có lỗi xảy ra. Vui lòng thử lại sau';

const ApiErrorMessage = {
  [ApiCodes.API_VERIFY_TOKEN_FAILED]:
    'Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại để tiếp tục',
  [ApiCodes.BAD_GATEWAY]:
    'Không thể kết nối với máy chủ. Vui lòng thử lại sau',
  [ApiCodes.GET_USER_INFO_FAIL]:
    'Không lấy được thông tin người dùng. Vui lòng thử lại sau.'
};

const ErrorMessages = {
  ...ApiErrorMessage
};

function messageWithError({ code, message = DEFAULT_MESSAGE }) {
  return ErrorMessages[code] || message;
}

export {
  ApiCodes,
  messageWithError,
  ApiErrorMessage,
  DEFAULT_MESSAGE
};
